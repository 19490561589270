import React from 'react';
import { ToolboxIcon } from './ToolboxIcon';

type Tool = {
  label: string;
  url: string;
  icon: JSX.Element;
};

export const toolList: Tool[] = [
  {
    label: 'GitHub',
    url: 'https://github.com/wistia',
    icon: <ToolboxIcon iconType="github" />,
  },
  {
    label: 'Slack',
    url: 'https://slack.com/',
    icon: <ToolboxIcon iconType="slack" />,
  },
  {
    label: 'Zoom',
    url: 'https://zoom.us/',
    icon: <ToolboxIcon iconType="zoom" />,
  },
  {
    label: 'Asana',
    url: 'https://asana.com/',
    icon: <ToolboxIcon iconType="asana" />,
  },
  {
    label: 'Cypress',
    url: 'https://dashboard.cypress.io/organizations/9d8b079e-5f52-42b4-91b0-42edc8f244e9/projects',
    icon: <ToolboxIcon iconType="cypress" />,
  },
  {
    label: '1Password',
    url: 'https://1password.com/',
    icon: <ToolboxIcon iconType="1password" />,
  },
  {
    label: 'Datadog',
    url: 'https://app.datadoghq.com/apm/home',
    icon: <ToolboxIcon iconType="datadog" />,
  },
  {
    label: 'Bugsnag',
    url: 'https://app.bugsnag.com/organizations/wistia/stability-center',
    icon: <ToolboxIcon iconType="bugsnag" />,
  },
  {
    label: 'Agora',
    url: 'https://console.agora.io/',
    icon: <ToolboxIcon iconType="agora" />,
  },
  {
    label: 'AWS',
    url: 'https://aws.amazon.com/',
    icon: <ToolboxIcon iconType="aws" />,
  },
  {
    label: 'Codecov',
    url: 'https://codecov.io/',
    icon: <ToolboxIcon iconType="codecov" />,
  },
  {
    label: 'PagerDuty',
    url: 'https://www.pagerduty.com',
    icon: <ToolboxIcon iconType="pagerduty" />,
  },
  {
    label: 'Tuple',
    url: 'https://tuple.app/',
    icon: <ToolboxIcon iconType="tuple" />,
  },
  {
    label: 'Fastly',
    url: 'https://www.fastly.com/',
    icon: <ToolboxIcon iconType="fastly" />,
  },
  {
    label: 'FullStory',
    url: 'https://fullstory.com/',
    icon: <ToolboxIcon iconType="fullstory" />,
  },
  {
    label: 'GTM',
    url: 'https://tagmanager.google.com/',
    icon: <ToolboxIcon iconType="gtm" />,
  },
  {
    label: 'Hubspot',
    url: 'https://www.hubspot.com/',
    icon: <ToolboxIcon iconType="hubspot" />,
  },
  {
    label: 'Mode',
    url: 'https://about.modeanalytics.com/',
    icon: <ToolboxIcon iconType="mode" />,
  },
  {
    label: 'Mux',
    url: 'https://mux.com/',
    icon: <ToolboxIcon iconType="mux" />,
  },
  {
    label: 'Nuke',
    url: 'https://www.foundry.com/products/nuke',
    icon: <ToolboxIcon iconType="nuke" />,
  },
  {
    label: 'Optimizely',
    url: 'https://www.optimizely.com/',
    icon: <ToolboxIcon iconType="optimizely" />,
  },
  {
    label: 'Postmark',
    url: 'https://postmarkapp.com/',
    icon: <ToolboxIcon iconType="postmark" />,
  },
  {
    label: 'Scalyr',
    url: 'https://www.scalyr.com/',
    icon: <ToolboxIcon iconType="scalyr" />,
  },
  {
    label: 'Scout',
    url: 'https://scoutapp.com/',
    icon: <ToolboxIcon iconType="scout" />,
  },
  {
    label: 'SendGrid',
    url: 'https://sendgrid.com/',
    icon: <ToolboxIcon iconType="sendgrid" />,
  },
  {
    label: 'Serverless',
    url: 'https://www.serverless.com/',
    icon: <ToolboxIcon iconType="serverless" />,
  },
  {
    label: 'Statuspage',
    url: 'https://www.statuspage.io/',
    icon: <ToolboxIcon iconType="statuspage" />,
  },
  {
    label: 'Zendesk',
    url: 'https://www.zendesk.com/',
    icon: <ToolboxIcon iconType="zendesk" />,
  },
];
