import React from 'react';
import { Content, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Grid, makeStyles } from '@material-ui/core';
import {
  HomePageToolkit,
  HomePageCompanyLogo,
  HomePageStarredEntities,
  WelcomeTitle,
} from '@backstage/plugin-home';
import { BackgroundImage, WistiaLogoNew } from '@wistia/vhs';
import { toolList } from './toolList';

const useStyles = makeStyles(theme => ({
  searchBarRoot: {
    display: 'flex',
    maxWidth: '60vw',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    padding: '8px',
    borderRadius: '50px',
    margin: 'auto',
  },
  searchBarOutline: {
    borderStyle: 'none',
  },
}));

export const HomePage = () => {
  const classes = useStyles();

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <Content>
          <Grid container justifyContent="center">
            <BackgroundImage
              style={{ alignItems: 'center' }}
              height="200px"
              width="655px"
            >
              <HomePageCompanyLogo logo={<WistiaLogoNew />} />
            </BackgroundImage>
          </Grid>
          <Grid container justifyContent="center">
            <Grid container item xs={12} alignItems="center" direction="row">
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarRoot,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                clearButton={false}
                placeholder="Search"
              />
            </Grid>
            <Grid item>
              <WelcomeTitle />
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} md={6}>
                <HomePageStarredEntities />
              </Grid>
              <Grid item xs={12} md={6}>
                <HomePageToolkit title="Toolbox" tools={toolList} />
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};
